import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll'
import styled from 'styled-components';
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { cacheImages } from '../utils/cacheImages';


import PrePayNavBar from "../components/home/PrePayNavBar"
import PrePayNews from '../components/home/PrePayNews'
import ContactModal from '../components/contact/ContactModal'
import DownloadModal from '../components/contact/DownloadModal'
import logo from '../assets/structure/logo-donegertobe.svg'

import m1 from '../assets/ppw-imgs/m1.webp'
import m2 from '../assets/ppw-imgs/m2.webp'
import m3 from '../assets/ppw-imgs/m3.webp'
import m4 from '../assets/ppw-imgs/m4.webp'
import m5 from '../assets/ppw-imgs/m5.webp'
import m6 from '../assets/ppw-imgs/m6.webp'
import m7 from '../assets/ppw-imgs/m7.webp'
import m8 from '../assets/ppw-imgs/m8.webp'
import m9 from '../assets/ppw-imgs/m9.webp'

import b1 from '../assets/ppw-imgs/b1.webp'
import b2 from '../assets/ppw-imgs/b2.webp'
import b3 from '../assets/ppw-imgs/b3.webp'
import b4 from '../assets/ppw-imgs/b4.webp'

import p1 from '../assets/ppw-imgs/p1.webp'
import p2 from '../assets/ppw-imgs/p2.webp'
import p5 from '../assets/ppw-imgs/p5.webp'
import p6 from '../assets/ppw-imgs/p6.webp'
import HolidayModal from '../components/home/HolidayModal';


const PrePay = styled.div`
    
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 5px;
    @media (min-width: 768px) {
        scroll-snap-type: y mandatory;
        padding: 0;
        row-gap: 170px;
    }
`

const HeadlineCap = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    @media (min-width: 1280px) {
        margin-bottom: 270px;
    }
`

const Headline = styled.h1`
    font-size: 82px;
    letter-spacing: .07em;
    line-height: 1em;
    @media (min-width: 768px) {
        font-size: 87px;
    }
    @media (min-width: 1024px) {
        font-size: 97px;
    }
    @media (min-width: 1280px) {
        font-size: 120px;
    }
    @media (max-width: 767px){
        & br {
            display: none;
        }
    }
`



const HdlWord = styled.span`
    display: inline-block;
    opacity: ${props => props.active ? 1 : 0};
    transition: opacity 0.25s ease-out;
    @media (max-width: 767px) {
        transition-delay: 0s!important;
    }
`

const HdlWordPlus = styled(HdlWord)`
    
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;
    @media (min-width: 768px) {
        transform: translateY(${props => props.active ? 0 : `20px`});
    }
`

const Experts = styled(HdlWordPlus)`
    position: relative;

    & div {
        position: absolute;
        top: .15em;
        width: 100%;
    }
    
`

const ButtonConvoCap = styled.div`
    opacity: ${props => props.active ? 1 : 0};
    transition: all 350ms ease-out 2s;
    @media (min-width: 768px) {
        transition: all 350ms ease-out 3s;
    }
`

const ButtonConvo = styled.button`
    border: 1px solid #D8292F;
    background: #F8F8F8;
    color: #D8292F;
    height: 28px;
    
    font-weight: bold;
    letter-spacing: .1em;
    line-height: 1em;
    font-family: soleil, sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    transition: all 250ms ease-out;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0 2.45em;
    border-radius: 14px;
    &:hover {
        background: #D8292F;
        color: #F8F8F8;
    }
    @media (min-width: 768px) {
        font-size: 21px;
        width: 100%;
        max-width: 650px;
        height: 40px;
        padding: 0;
        border-radius: 20px;
    }
    @media (min-width: 1024px) {
        max-width: 720px;
    }
    @media (min-width: 1280px) {
        max-width: 885px;
    }

`

const Offering = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 36px;
    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        column-gap: 50px;
    }
    @media (min-width: 1024px) {
        column-gap: 90px;
    }
    @media (min-width: 1280px) {
        column-gap: 100px;
    }
    @media (min-width: 1550px) {
        column-gap: 120px;
    }
`

const OfferingInfo = styled.div`
    
    display: flex; 
    flex-direction: column;
    row-gap: 18px;
 @media (min-width: 768px) {
        width: 48%;
        row-gap: 25px;
    }  
    @media (min-width: 1280px) {
        row-gap: 25px;
    }  
`

const OfferingGallery = styled.div`
    display: none;
    @media (min-width: 768px) {
        display: block;
        width: 52%;  
        transition: all 250ms ease-out;
        opacity: ${props => props.loaded ? 1 : 0};
    }  
`

const OfferingSubHdl = styled.div`
    margin: 0;
    font-family: gopher, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 17px;
    line-height: 1em;
    letter-spacing: .07em;
    text-transform: uppercase;
    color: #D8292F;
    @media (min-width: 768px) {
        font-size: 16px;
    }    
    @media (min-width: 1280px) {
        font-size: 20px;
    }
`
const OfferingHdl = styled.h1`
    margin: 0;
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1em;
    letter-spacing: .07em;
    font-size: 44px;
    @media (min-width: 768px) {
        font-size: 48px;
    }
    @media (min-width: 1024px) {
        font-size: 50px;
    }
    @media (min-width: 1280px) {
        font-size: 60px;
    }
`

const OfferingTxt = styled.p`
    margin: 0;
    font-style: normal;
    font-family: soleil, sans-serif;
    font-weight: 300;
    letter-spacing: .08em;
    line-height:  1.5em;
    font-size: 19px;
    @media (min-width: 768px) {
        font-size: 20px;
        line-height:  1.5em;
    }
    @media (min-width: 1280px) {
        font-size: 25px;
        line-height:  1.3em;
        padding-right: 2em;
    }
`

const OfferingAction = styled.p`
    margin: 0;
    font-family: freight-big-pro, serif;
    font-weight: 300;
    font-style: normal;
    line-height: 1em;
    letter-spacing: .08em;
    line-height:  1.25em;
    font-size: 19px;
    color: #D8292F;
    @media (min-width: 1280px) {
        font-size: 25px;
    }
`

const OfferingButton = styled.button`
    margin: 0;
    font-style: normal;
    font-family: soleil, sans-serif;
    font-weight: 300;
    letter-spacing: .06em;
    line-height: 1em;
    color: #D8292F;
    text-decoration: none;
    font-size: 15px;
    text-transform: uppercase;
    height: 28px;
    padding: 0 2em;
    align-self: flex-start;
    border: 1px solid #D8292F;
    transition: all 250ms ease-out;
    border-radius: 14px;
    background: #F8F8F8;
    cursor: pointer;
    &:hover {
        background: #D8292F;
        color: #F8F8F8;
    }
    @media (min-width: 768px) {
        font-size: 16px;
        height: 38px;
        border-radius: 19px;
    }
}`

const GalleryGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 29px;
`
const GalleryGridItem = styled.div`
    aspect-ratio: 1 / 1;
    line-height: 0;
    box-shadow: 0px 3px 6px #00000029;
    overflow: hidden;
`

const GalleryGridDiamond = styled(GalleryGrid)`
    grid-template-columns: 1fr 1fr;
    transform: rotate(45deg);
    grid-gap: 40px;
    padding: 0 85px;
    margin-top: 75px;
    & img {
        transform: rotate(-90deg) scale(1);
    }
    @media (min-width: 768px) {
        margin-top: 0px;
        padding: 0 40px;
    }
    @media (min-width: 1024px) {
        padding: 0 35px;
    }
    @media (min-width: 1280px) {
        padding: 0 15%;
    }
    @media (min-width: 1550px) {
        ___padding: 0 45px;
    }
`

const GalleryGridTrio = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 65px;
    calc(100% + 40px)
    margin: -20px 0;
    
`

const GalleryCol = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 50px;
`

const GalleryColStagger = styled.div`
    display: grid;
    grid-template-rows: 2fr 2fr;
    row-gap: 65px;
    padding-top: 50px;
`

const AboutTabs = styled.div`
    margin: 150px -15px;
    padding: 0 15px;
    background: #F8F8F8;
    @media (min-width: 768px) {
        padding: 0;
    }    
    @media (min-width: 1024px) {
        margin: 150px -15%;
    }
    @media (min-width: 1550px) {
        margin: 150px -15px;
    }
`

const AboutText = styled.div`

    & > p {
        font-family: gopher, sans-serif;
        line-height: 1.75em;
        font-size: 16px;
        letter-spacing: .06em;
        margin: 0 0 2em;
        text-align: left;
    }
    & > p:last-child {
        xxxmargin: 0;
    }
    & > p > span {
        font-weight: 400;
    }    
    & > p > span > span {
        color: rgb(216, 41, 47);
    }
    @media (min-width: 768px) {
        & > p {
            font-size: 22px;
            line-height: 2em;
        }
    }
`

const Divider = styled.div`
    display: none;
    @media (min-width: 768px) {
        display: block;
        width: 100%;
        border-top:1px solid #ccc;
    }

`

const Section = styled.div`
    margin: 206px auto 0; 
    
    width: 100%;
    max-width: 1150px;
    display: flex;
    row-gap: 100px;
    flex-direction: column;
    transition: all 300ms ease-out;
    @media (min-width: 768px) {
        row-gap: 300px;
        padding: 0 36px;
    }
`

const SectionTabs = styled(Section)`
    margin: 0 auto;
    text-align: center;
    padding: 30px 0;
    row-gap : 0;
    @media (min-width: 768px) {
        padding: 75px 0;
    }
`

const SectionOfferings = styled(Section)`
    margin: 50px auto 0;
    row-gap: 160px;
    @media (min-width: 768px) {
        margin: ${props => props.scrolled ? "206px auto 0" : "355px auto 0"};
    }
`

const Tabs = styled.div`
    text-align: center;
    margin-bottom: 25px;
    font-family: gopher, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    line-height: 1em;
    letter-spacing: .07em;
    @media (min-width: 768px) {
        font-size: 29px;
        margin-bottom: 35px;
    }

`

const Tab = styled.span`
    color: ${props => props.active ? `#D8292F` : `#222`};
    text-decoration: ${props => props.active ? `underline` : `none`};
    cursor: pointer;
    text-transform: uppercase;
`

const TabContent = styled.div`
    padding: 15px 0 0;
    text-align: left;
    @media (min-width: 768px) {
        padding: 50px 50px 0;
    }
`

const Image = styled.img`
    XXXobject-fit: cover;
    width: 100%;
    height: auto;
`

const LogoMobile = styled.img`
    margin-top: 70px;
    @media (min-width: 768px) {
        display: none;
    }
`


const MobileLine = styled.span`
@media (max-width: 767px) {
    display: block;
} 
`

const PrePayWall = () => {

    const [open, setOpen] = useState(false);

    const downloads = {
        "design-movements": {
            title: "Design Movements",
            text: "Incoming design directions and how they will play out in product, styling, visual display, interiors, and digital expression."
        },
        "the-index": {
            title: "The Index",
            text: "A peek at emerging designers, promising partnerships, and rising brands spotted by our eagle-eyed analysts each month."
        },
        "at-retail": {
            title: "At Retail",
            text: "Our merchandising expertise ensures you never miss a fashion moment."
        },
    }

    const [dlOpen, setDlOpen] = useState("");
    const [holidayOpen, setHolidayOpen] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [tab, setTab] = useState(`about`)
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {

        const srcArr = [
            m1, m2, m3, m4, m5, m6, m7, m8, m9,/*  b1, b2, b3, b4, p1, p2, p3, p4, p5, p6 */
        ]

        const timer = setTimeout(async () => {
            await cacheImages(srcArr);
            setLoaded(true)
        }, 0);

        return () => clearTimeout(timer);
    }, [])

    useEffect(() => {
        if (!holidayOpen) {
            const timer = setTimeout(() => {
                setIsActive(true)
            }, 1000);
            return () => clearTimeout(timer);
        }

    }, [holidayOpen])


    return (
        <>
            <PrePay>
                <PrePayNavBar homeLink={true} scrolled={scrolled} setScrolled={setScrolled} />

                <LogoMobile src={logo} alt="DONEGER|TOBE" width="1150" height="122" />

                <SectionOfferings scrolled={scrolled}>


                    <HeadlineCap>
                        {!holidayOpen &&
                            <Headline>

                                <MobileLine>

                                    <Experts active={isActive} style={{ "transitionDelay": `0s` }}>
                                        <span style={{ zIndex: 998, position: "relative" }}>Experts</span>
                                        <div style={{ zIndex: 997 }}>
                                            <svg className="pathExperts" viewBox="0 0 102.21 2.96"><defs>
                                            </defs><path id="Path_104" d="M.5,1.23C89.09,4,100.32,1.34,101.71.5" transform="translate(0 0)" /></svg>
                                        </div>
                                    </Experts></MobileLine>  <MobileLine><HdlWordPlus active={isActive} style={{ "transitionDelay": `0s` }}>in</HdlWordPlus> <br /><HdlWord active={isActive} style={{ "transitionDelay": `.75s` }}>retail </HdlWord></MobileLine> <MobileLine><HdlWord active={isActive} style={{ "transitionDelay": `1.25s` }}><i>and</i></HdlWord></MobileLine> <HdlWord active={isActive} style={{ "transitionDelay": `1.75s` }}>fashion.</HdlWord>
                            </Headline>
                        }
                        <ButtonConvoCap active={isActive} >
                            <ButtonConvo onClick={() => setOpen(true)} >Start the Conversation</ButtonConvo>
                        </ButtonConvoCap>
                    </HeadlineCap>


                    <Offering>
                        <OfferingInfo>
                            <OfferingSubHdl>We're Specialists In</OfferingSubHdl>
                            <OfferingHdl>Merchandising + Creative Strategy</OfferingHdl>
                            <OfferingTxt>Assessing the business by market and applying creative direction to project and plan for the next 12-18 months.</OfferingTxt>
                            <OfferingAction>Check our instincts with this design projection.</OfferingAction>
                            <OfferingButton onClick={() => setDlOpen("design-movements")}>Get it here</OfferingButton>
                        </OfferingInfo>
                        <OfferingGallery loaded={loaded}>
                            <GalleryGrid>
                                <GalleryGridItem><Image src={m1} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m2} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m3} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m4} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m5} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m6} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m7} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m8} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={m9} alt="Merchandising" width="300" height="300" /></GalleryGridItem>
                            </GalleryGrid>
                        </OfferingGallery>
                    </Offering>

                    <Divider />

                    <Offering>
                        <OfferingInfo>
                            <OfferingSubHdl>Leading the Charge In</OfferingSubHdl>
                            <OfferingHdl>Brand Discovery + Curation</OfferingHdl>
                            <OfferingTxt>Evaluating all categories and editing the market to guide and inspire the best assortments.</OfferingTxt>
                            <OfferingAction>Crafting an exciting offering means staying fresh.</OfferingAction>
                            <OfferingButton onClick={() => setDlOpen("the-index")}>Take a Look</OfferingButton>
                        </OfferingInfo>
                        <OfferingGallery loaded={loaded}>
                            <GalleryGridDiamond>
                                <GalleryGridItem><Image src={b1} alt="Brand Discovery" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={b2} alt="Brand Discovery" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={b3} alt="Brand Discovery" width="300" height="300" /></GalleryGridItem>
                                <GalleryGridItem><Image src={b4} alt="Brand Discovery" width="300" height="300" /></GalleryGridItem>
                            </GalleryGridDiamond>
                        </OfferingGallery>
                    </Offering>

                    <Divider />

                    <Offering>
                        <OfferingInfo>
                            <OfferingSubHdl>With a Focus On</OfferingSubHdl>
                            <OfferingHdl>Presentation + Engagement</OfferingHdl>
                            <OfferingTxt>Creating the most compelling selling floors, displays, and activations in sync with the consumer zeitgeist.</OfferingTxt>
                            <OfferingAction>Understanding what's winning at retail is critical.</OfferingAction>
                            <OfferingButton onClick={() => setDlOpen("at-retail")}>View it now</OfferingButton>
                        </OfferingInfo>
                        <OfferingGallery loaded={loaded}>
                            <GalleryGridTrio>
                                <GalleryCol>
                                    <GalleryGridItem><Image src={p1} alt="Presentation" width="300" height="300" /></GalleryGridItem>
                                    <GalleryGridItem><Image src={p6} alt="Presentation" width="300" height="300" /></GalleryGridItem>

                                </GalleryCol>
                                <GalleryColStagger>

                                    <GalleryGridItem style={{ gridRow: "1/3" }}><Image src={p2} alt="Presentation" width="300" height="300" /></GalleryGridItem>
                                    <GalleryGridItem style={{ gridRow: "3/5" }}><Image src={p5} alt="Presentation" width="300" height="300" /></GalleryGridItem>
                                </GalleryColStagger>

                            </GalleryGridTrio>
                        </OfferingGallery>
                    </Offering>


                </SectionOfferings>

                <Element name={`about`}>
                    <AboutTabs>
                        <SectionTabs>

                            <Tabs>
                                <Tab onClick={() => setTab(`about`)} active={tab === `about`}>About Us</Tab> / <Tab onClick={() => setTab(`press`)} active={tab === `press`}>Press</Tab>
                            </Tabs>
                            <TabContent>
                                <SwitchTransition>
                                    <CSSTransition
                                        key={tab + Math.random()}
                                        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
                                        classNames='fade2'
                                    >
                                        {tab === `about` ?
                                            <AboutText>
                                                <p><span>The Doneger Group</span> and <span><span>TOBE</span></span>, founded in 1946 and 1927 respectively, joins forces to create an exceptionally forward-thinking and leverageable consultancy for the retail and fashion industries.</p>
                                                <p><span>DONEGER |</span> <span><span>TOBE</span></span> delivers business and creative strategies on merchandising & assortment planning, and color & trend forecasting, including private label design, with attention to the relevant curation & presentation of product.</p>
                                                <p><span>DONEGER |</span> <span><span>TOBE</span></span> elevates and activates the traditional retail advisory capabilities for a faster, smarter competitive landscape. Apparel retailers and fashion brands tap into this network of stay-ahead positioning to keep their stores and brands on point.</p>
                                            </AboutText>
                                            :
                                            <PrePayNews />
                                        }
                                    </CSSTransition>
                                </SwitchTransition>

                            </TabContent>
                        </SectionTabs>
                    </AboutTabs>
                </Element>

            </PrePay>

            {/* <CSSTransition
                in={holidayOpen}
                timeout={300}
                classNames="contact-modal"
                unmountOnExit
            >
                <HolidayModal open={holidayOpen} setOpen={setHolidayOpen} />
            </CSSTransition> */}

            <CSSTransition
                in={open}
                timeout={300}
                classNames="contact-modal"
                unmountOnExit
            >
                <ContactModal open={open} setOpen={setOpen} captureType={`prospect`} originSource={`FOS`} originDetail={`Start Conversation`} title={<>Interested in learning more? <br />Let’s set a meeting.</>} successMsg={`We’ll meet you in your inbox to schedule a time.`} />
            </CSSTransition>

            <CSSTransition
                in={dlOpen}
                timeout={300}
                classNames="contact-modal"
                unmountOnExit
            >
                <DownloadModal open={dlOpen ? true : false} setOpen={setDlOpen} captureType={`prospect`} originSource={`FOS`} originDetail={downloads[dlOpen] && downloads[dlOpen].title} title={downloads[dlOpen] && downloads[dlOpen].title} text={downloads[dlOpen] && downloads[dlOpen].text} successMsg={`We’ll see you in your inbox.`} />
            </CSSTransition>
        </>
    );
};

export default PrePayWall;

