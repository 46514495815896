import React, { useEffect } from 'react';
import styled from 'styled-components'
import FadeIn from "react-fade-in";
import BreadCrumb from '../components/BreadCrumb'
import ContactPublic from '../components/contact/ContactPublic'
import PrePayNavBar from "../components/PrePayNavBar"
import doubleLines from '../assets/fos-doublelines.svg'

const Container = styled.div`
    width: 100%;
    max-width: 1084px;
    margin: -40px auto 0;
`


const AboutTitle = styled.h1`
    margin:0;
    font-family: gopher;
    font-size: 40px;
    letter-spacing: .1em;
    line-height: 3em;
    font-weight: 300;
    text-transform: lowercase;
    text-align: center;
    border-bottom: 1px solid #ccc;
    @media (min-width: 768px) {
        font-size: 40px;
    }    
`

const AboutText = styled.div`
    padding: 30px 0 0;
    & > p {
        font-family: gopher, sans-serif;
        line-height: 1.82em;
        font-size: 14px;
        letter-spacing: .06em;
        margin: 0 0 2em;
        text-align: center;
    }
    & > p:last-child {
        margin: 0;
    }
    & > p > span {
        color: rgb(216, 41, 47);
        font-weight: 300;
    }    
    @media (min-width: 768px) {
        padding: 50px 50px 0;
        & > p {
            font-size: 22px;
        }
    }
`

const AboutText2 = styled.div`
    padding: 15px 0 0;
    & > p {
        font-family: gopher, sans-serif;
        line-height: 2em;
        font-size: 12px;
        letter-spacing: .06em;
        margin: 0 0 2em;
        text-align: left;
    }
    & > p:last-child {
        margin: 0;
    }
    & > p > span {
        font-weight: 400;
    }    
    & > p > span > span {
        color: rgb(216, 41, 47);
    }
    @media (min-width: 768px) {
        padding: 50px 50px 0;
        & > p {
            font-size: 22px;
        }
    }
`

const Divider = styled.div`
    margin: 1em 0;
    padding: 1em 0;
`

const Divider1 = styled(Divider)`
    margin: 1em 0 0;
    padding: 1em 0 0;
    border-bottom: 1px solid #ccc;

`


const Divider4 = styled(Divider)`
    margin: 1em 0 0;
@media (min-width: 768px) {
    margin: 1em 0 2em;
    padding: 1em 0;
}
`

const BrainTrust = styled.p`
    margin: 0;
    font-family: gopher;
    font-size: 22px;
    letter-spacing: .1em;
    line-height: 1.42em;
    padding-left: 2em;
    padding-bottom: .5em;
    text-align: center;
    & > span {
        display: inline-block;
        background-position: 0 1.3em;
        padding-bottom: 1em;
        padding-right: 1em;
        padding-left: .25em;
        margin-left: .10em;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(${doubleLines});
        font-weight: 300;
        
    }
    & > span:after {
        content: " ";
    }
    & > span > span {
        color: #D8292F; 
    }
    @media (min-width: 768px) {
        font-size: 29px;
        margin: 0 1em;
    }
    @media (min-width: 1024px) {
        font-size: 35px;
    }
    @media (min-width: 1280px) {
  
    }
    @media (max-width: 767px) {
        margin: -2em auto 0;
        padding: 0;
        max-width: 70%;
    }
`


const AboutUs = ({ project }) => {

    document.title = "About Us";
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <>
            <PrePayNavBar intro={true} homeLink={false} />
            <FadeIn>
                <Container>
                    <BreadCrumb path={`/`} title={`Back`} />
                    <AboutTitle>Who We Are</AboutTitle>
                    {/*            <AboutText>
                <p>Imagine you have a team of great brains, critical thinkers with an eye for style and aesthetics, available to ideate on new initiatives, topics of interest, and stubborn areas of business. And, when you don’t have a specific objective, you can count on a steady stream of creative thinking, thoughtful observations, and expertly filtered business directives. Envision your leadership energized by new ideas and collaborating to bring them to life for your brand.</p>

            </AboutText>

            <Divider/>
                <BrainTrust>This is<span>DONEGER | <span>TOBE</span></span></BrainTrust>
            <Divider1/> */}
                    <AboutText2>
                        {/* <p>Venerable industry advisors, ≈≈≈≈    The Doneger Group</span> and <span><span>TOBE</span></span>, founded in 1946 and 1927 respectively, joined forces to create an exceptionally forward-thinking and leverageable insights agency and consultancy. This modern model for teasing consumer sentiment and commercial opportunity out of the sociocultural and creative landscape predicts big shifts two to five years in advance.</p>
                <p><span>DONEGER | <span>TOBE</span></span> is a special ops team for great companies, delivering business and creative strategies on branding & positioning, consumer engagement, merchandising & curation, design direction, and marketing & messaging.</p>
                <p>Focused on how consumers interact with brands today, our team develops and evolves concepts that inspire new strategies and encourage innovation to help clients stay relevant. Collectively DONEGER | TOBE’s roster of experts-in-their-fields operate as a think tank, a brain trust, approaching each effort with fresh eyes, critical thinking and discerning analysis.</p> */}
                        {/*   <p><span>The Doneger Group</span> and <span><span>TOBE</span></span>, founded in 1946 and 1927 respectively, joins forces to create an exceptionally forward-thinking and leverageable insights agency and consultancy. Our modern model for teasing consumer sentiment and commercial opportunity out of the sociocultural and creative landscape predicts big shifts two to five years in advance.</p>
                <p><span>DONEGER | <span>TOBE</span></span> is a special ops team for smart companies. We deliver business and creative strategies on branding & positioning, consumer engagement, merchandising & curation, design direction, and marketing & messaging. Focused on how consumers interact with brands today, our team develops and evolves concepts that inspire new strategies and encourage innovation to help clients stay relevant. Collectively <span>DONEGER | <span>TOBE</span></span>’s roster of venerable industry advisors approaches each effort with discerning analysis.</p> */}
                        <p><span>The Doneger Group</span> and <span><span>TOBE</span></span>, founded in 1946 and 1927 respectively, joins forces to create an exceptionally forward-thinking and leverageable insights agency and consultancy. Our modern model for teasing consumer sentiment and commercial opportunity out of the sociocultural and creative landscape predicts big shifts two to five years in advance.</p>
                        <p><span>DONEGER |</span> <span><span>TOBE</span></span> is a special ops team for smart companies. We deliver business and creative strategies on branding & positioning, consumer engagement, merchandising & curation, design direction, and marketing & messaging. Focused on how consumers interact with brands today, our team develops and evolves concepts that inspire new strategies and encourage innovation to help clients stay relevant. Collectively <span>DONEGER |</span> <span><span>TOBE</span></span>’s roster of venerable industry advisors approaches each effort with discerning analysis.</p>
                    </AboutText2>
                    <Divider4 />


                    {project && <ContactPublic />}
                </Container>


            </FadeIn>
        </>
    );
};

export default AboutUs;